<template>
  <div class="material-card-content">
    <p class="title">
      Subscription
    </p>
    <card
      class="modal-card-body"
      external-card="card material-card"
      internal-card="material-card-content"
      url-help=""
    >
      <!-- <p
        v-show="trialDaysRemaing && trialDaysRemaing < 1"
        class="viewDescription"
      >
        The <b>trial</b> of {{ currentCompany ? currentCompany.name : 'your company' }} on
        Boardgent is <b>expired</b>.
        <span v-if="$isBgCloud">You can renew it here.</span>
      </p>
      <p
        v-show="trialDaysRemaing && trialDaysRemaing > 0"
        class="viewDescription"
      >
        The <b>trial</b> ends in <b>{{ trialDaysRemaing }} days</b>.
        <span v-if="$isBgCloud">Upgrade now!</span>
      </p>
      <p
        v-show="!trialDaysRemaing && subscriptionExpired"
        class="viewDescription"
      >
        The <b>subscription</b> of {{ currentCompany ? currentCompany.name : 'your company' }} on
        Boardgent is <b>expired</b>.
        <span v-if="$isBgCloud">You can renew it here.</span>
      </p>
      <p
        v-show="!trialDaysRemaing && !subscriptionExpired"
        class="viewDescription"
      >
        Here are the available plans for your account.
      </p> -->
      <section
        slot="header"
      >
        <p class="viewDescription">
          Thanks for your interest
        </p>
      </section>
      <section
        slot="body"
      >
        <div
          class="columns is-multiline"
        >
          <p class="descriptionStyle">
            Currently, we are improving Boardgent to offer you more benefits and actions that
            allow you to make your IT tasks easier and faster.
            Sadly, during these changes, we are not allowing new accounts to make these
            improvements be available for you as fast as possible.
          </p>
          <p class="descriptionStyle">
            We will notify you when all those improvements are ready and you can continue
            with the registration process.
          </p>
          <p class="descriptionStyle">
            If you need special assistance, you can always talk to us by our chat at the
            bottom-right corner or send us an email at
            <a href="mailto:support@boardgent.com">support@boardgent.com</a>
          </p>
        </div>
      </section>
      <!-- <b-tabs
        v-if="$isBgCloud"
        v-model="activeTab"
        position="is-centered"
      >
        <b-tab-item label="Yearly Plans">
          <div
            style="text-align:center"
            class="columns is-multiline is-centered"
          >
            <paymentPlanCard
              v-for="(plan, index) in plans.filter((plan) => plan.interval === 'year')"
              :key="index"
              :plan="plan"
              :show-payment-form="showPaymentForm"
              :selected-plan="selectedPlan"
            />
            <paymentPlanCard
              v-if="plans.length > 0"
              :plan="{
                metadata: {
                  HIDDEN: 'false',
                  MOST_POPULAR_PLAN: 'false',
                  PROMOTIONAL_TEXT: 'You have more of 1000 devices? Contact us!',
                  PRICE: null,
                },
                nickname: 'Custom plan',
                interval: null,
              }"
              :show-payment-form="showPaymentForm"
              :selected-plan="selectedPlan"
            />
            <b-loading
              :is-full-page="false"
              :active="isLoading"
              :can-cancel="false"
            />
          </div>
        </b-tab-item>
        <b-tab-item label="Monthly Plans">
          <div
            style="text-align:center"
            class="columns is-multiline is-centered"
          >
            <paymentPlanCard
              v-for="(plan, index) in plans.filter((plan) => plan.interval === 'month')"
              :key="index"
              :plan="plan"
              :show-payment-form="showPaymentForm"
              :selected-plan="selectedPlan"
            />
            <paymentPlanCard
              v-if="plans.length > 0"
              :plan="{
                metadata: {
                  HIDDEN: 'false',
                  MOST_POPULAR_PLAN: 'false',
                  PROMOTIONAL_TEXT: 'You have more of 1000 devices? Contact us!',
                  PRICE: null,
                },
                nickname: 'Custom plan',
                interval: null,
              }"
              :show-payment-form="showPaymentForm"
              :selected-plan="selectedPlan"
            />
            <b-loading
              :is-full-page="false"
              :active="isLoading"
              :can-cancel="false"
            />
          </div>
        </b-tab-item>
      </b-tabs> -->
      <b-collapse :open.sync="showPayForm">
        <div v-if="!$isBgCloud">
          <br>
          <p>
            You are not authorized to access or modify this settings,
            please contact us (<a href="mailto:contact@boardgent.com">contact@boardgent.com</a>).
          </p>
        </div>
        <div v-else-if="!isAdmin">
          <p>
            You are not authorized to access or modify this settings,
            please contact your administrator.
          </p>
        </div>
        <div v-else-if="isAdmin && cards.length > 0 && !showAddNewCard">
          <p class="payment-subtitle">
            Select Credit Card
          </p>
          <b-field>
            <b-select
              v-model="cardSelected"
              placeholder="Select a card"
              expanded
              @input="onSelectCard"
            >
              <option
                v-for="item in cards"
                :key="item.id"
                :value="item.id"
              >
                XXXX-XXXX-XXXX-{{ item.card.last4 }}
              </option>
            </b-select>
          </b-field>
          <div style="display:flex;margin-top:6px">
            <button
              class="button is-danger actionButton force-right"
              @click="showAddNewCard = true"
            >
              Pay with another card
            </button>
            <button
              v-show="cardSelected"
              class="button is-primary actionButton"
              @click="forcePay(false)"
            >
              Pay ${{ (devices.length * planPrice) - availableBalance }}
            </button>
          </div>
        </div>
        <div v-else-if="isAdmin && cards.length < 1 || showAddNewCard">
          <p class="payment-subtitle">
            Add a new card
          </p>
          <getCardInfo
            :submit-btn-text="$router.history.current.name === 'selectplan'
              && !currentCompany.trialAlreadyUsed ? 'Start trial'
              : `Pay $${(devices.length * planPrice) - availableBalance}`"
            :show-cancel="cards.length > 0"
            :on-cancel="() => {showAddNewCard = false}"
            :on-success="onAddNewCard"
          />
        </div>
      </b-collapse>
      <b-loading
        :is-full-page="false"
        :active="isPaying"
        :can-cancel="false"
      />
    </card>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import moment from 'moment';

import getCardInfo from '@/components/billing/GetCardInfo.vue';
// import paymentPlanCard from '@/components/billing/PaymentPlanCard.vue';
import card from '@/components/cross/Card.vue';

import snackBarMessage from '@/helpers/snackBarMessage';
import Subscription from '@/helpers/subscription';

import ErrorMixin from '@/mixins/error';
import RoleMixin from '@/mixins/roles';
import analyticsMixin from '@/mixins/analytics';

import vtulEnums from '../../../cross/index';

const { operationalUiErrors } = vtulEnums.enum.ui;
const { alertBox } = vtulEnums.enum.alert;
const { stripeQuery } = vtulEnums.enum.stripe;
const { permissions } = vtulEnums.enum.roles;

export default {
  name: 'ExpiredSubscription',
  metaInfo: {
    title: 'Subscription',
  },
  components: {
    getCardInfo,
    // paymentPlanCard,
    card,
  },
  mixins: [ErrorMixin, RoleMixin, analyticsMixin],
  data() {
    return {
      availableBalance: 0,
      cards: [],
      defaultCardId: null,
      showAddNewCard: false,
      showPayForm: !this.$isBgCloud,
      isPaying: false,
      planPrice: 0,
      planId: null,
      cardSelected: null,
      trialDaysRemaing: null,
      isAdmin: false,
      selectedPlan: '',
      plans: [],
      isLoading: false,
      activeTab: 0,
    };
  },
  computed: {
    ...mapGetters('companies', { currentCompany: 'getCurrentCompany' }),
    ...mapGetters('devices', { findDevicesInStore: 'find' }),
    devices() {
      const devices = this.findDevicesInStore({
        query: {
          companyId: this.currentCompanyId,
          enabled: true,
          $sort: { createdAt: -1 },
        },
      });
      return devices && devices.data ? devices.data : [];
    },
    subscriptionExpired() {
      return Subscription.subscriptionExpired(this.currentCompany);
    },
    currentCompanyId() {
      return this.currentCompany ? this.currentCompany.id : null;
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  mounted() {
    const plugin = document.createElement('script');
    plugin.setAttribute(
      'src',
      'https://assets.calendly.com/assets/external/widget.js',
    );
    plugin.async = true;
    document.head.appendChild(plugin);
    if (this.$isBgCloud) {
      this.isLoading = true;
      this.getStripeCustomer([this.currentCompany.stripeCustomerId, {
        query: { companyId: this.currentCompany.id },
      }]).then(async (customer) => {
        const roleOfUser = await this.currentPermissions();
        if (roleOfUser.isOwner
            || this.validateIfHasPermission([
              permissions.MANAGE_COMPANY_PLAN.value.dbValue,
            ])) {
          this.isAdmin = true;
          this.setFetchedData(customer);
        }
        this.findAllPlans({
          query: {
            stripeQuery: stripeQuery.ALLPLANS.value,
            customerId: this.currentCompany.stripeCustomerId,
            companyId: this.currentCompany.id,
          },
        }).then((data) => {
          data.forEach((plan) => this.plans.push({
            ...plan,
            trialTime: 14,
          }));
          this.isLoading = false;
        }).catch((error) => { throw new Error(error); });
      }).catch((error) => { throw new Error(error); });
    }
    this.fetchDevicesFromApi();
  },
  methods: {
    ...mapActions('devices', { findDevices: 'find' }),
    ...mapActions('companies', { updateCompany: 'patch' }),
    ...mapActions('stripe-company-information', { getStripeCustomer: 'get', updateCustomer: 'patch', findAllPlans: 'find' }),
    ...mapActions('companies', ['changeCurrentCompany']),
    ...mapActions('memberships', { findMembershipInApi: 'find' }),
    ...mapMutations(['clearCurrentSelections']),
    showPaymentForm(id, planPrice) {
      this.showPayForm = true;
      this.planId = id;
      this.planPrice = planPrice;
      this.selectedPlan = id;
    },
    onAddNewCard(source) {
      this.trackEvent(!this.currentCompany.isTestData && !this.user.isTestData,
        'track', 'Add Card', {
          brand: source.card.brand,
          country: source.card.country,
          cvc_check: source.card.cvc_check,
          funding: source.card.funding,
          name: source.card.name,
          tokenization_method: source.card.tokenization_method,
        });
      this.isPaying = true;
      this.cardSelected = source.id;
      this.updateCustomer([this.currentCompany.stripeCustomerId, {
        sourceId: source.id,
        defaultSourceId: this.cards.length > 0 ? source.id : null,
        forcePay: false,
        companyId: this.currentCompany.id,
      }, {}]).then(() => {
        this.forcePay(true);
        snackBarMessage.showSuccess('Your card was added.');
      }).catch((err) => {
        this.isPaying = false;
        this.throwVtulError(operationalUiErrors.PAYMENT_CARD_ERROR,
          alertBox.SNACKBAR.value, err);
      });
    },
    async currentPermissions() {
      const { data } = await this.findMembershipInApi({
        query: {
          $limit: 1,
          companyId: this.currentCompany.id,
          userId: this.$store.getters['auth/user'].id,
        },
      });
      if (data.length === 1) {
        return data[0];
      }
      return '';
    },
    onSelectCard(value) {
      this.cardSelected = value;
    },
    forcePay(newCardSelected) {
      this.isPaying = true;
      this.trackEvent(!this.currentCompany.isTestData && !this.user.isTestData,
        'track', 'Pay Plan', {
          planId: this.planId,
          planPrice: this.planPrice,
          quantity: this.devices.length,
          total: this.devices.length * this.planPrice,
          newCardAddedToPay: newCardSelected,
          companyName: this.currentCompany.name,
          companyAddress: this.currentCompany.address,
          companyCity: this.currentCompany.city,
          companyCountry: this.currentCompany.country,
          companyStripeCustomerId: this.currentCompany.stripeCustomerId,
          companyUrl: this.currentCompany.url,
        });
      this.updateCustomer([this.currentCompany.stripeCustomerId, {
        // If use old card and it is not default => make the selected card default before pay
        defaultSourceId: (!newCardSelected && this.cardSelected
          && this.cardSelected !== this.defaultCardId) ? this.cardSelected : null,
        forcePay: true,
        currentDevicesQuantity: this.devices.length,
        companyId: this.currentCompany.id,
        planId: this.planId,
      }, {}]).then((data) => {
        this.isPaying = false;
        if (this.$router.history.current.name === 'upgradeplan') {
          snackBarMessage.showSuccess('The payment has been made successfully.');
        }
        const thisComponent = this;
        if (data.current_period_end) {
          this.updateCompany([this.currentCompanyId, {
            stripeExpirationDate: new Date(data.current_period_end * 1000),
          }, {}]).then((company) => {
            thisComponent.clearCurrentSelections();
            thisComponent.changeCurrentCompany(company);
            thisComponent.$router.push(`/${company.id}/devices`);
          }).catch((err) => {
            this.throwVtulError(operationalUiErrors.UPDATE_COMPANY_ERROR,
              alertBox.SNACKBAR.value, err);
          });
        } else {
          snackBarMessage.showError(operationalUiErrors.UPDATE_COMPANY_ERROR.value.message);
        }
      }).catch((err) => {
        this.isPaying = false;
        this.throwVtulError(operationalUiErrors.UNMADE_PAYMENT,
          alertBox.SNACKBAR.value, err);
      });
    },
    setFetchedData(customer) {
      this.availableBalance = customer.account_balance;
      this.cards = customer.sources.data.filter((x) => x.type === 'card');
      this.defaultCardId = customer.default_source;
      this.cardSelected = customer.default_source;
      const trialPlan = customer.subscriptions.data.find((x) => x.status === 'trialing');
      this.trialDaysRemaing = null;
      if (trialPlan) {
        const stripeEndDate = moment(trialPlan.current_period_end * 1000);
        this.trialDaysRemaing = (stripeEndDate.diff(moment(new Date()).add(2, 'minutes'), 'days') + 1);
      }
    },
    async fetchDevicesFromApi(skip) {
      let skipDevices = skip;
      if (!skipDevices) skipDevices = 0;
      const { total, data } = await this.findDevices({
        query: {
          $sort: { createdAt: -1 },
          $limit: 50,
          $skip: skipDevices,
          companyId: this.currentCompanyId,
        },
      });
      skipDevices += data.length;
      if (skipDevices < total) {
        await this.fetchDevicesFromApi(skipDevices);
      }
    },
  },
};
</script>

<style scoped>
@import 'https://assets.calendly.com/assets/external/widget.css';
.viewDescription {
  font-size: 21px;
  font-weight: 300;
  margin-bottom: 30px;
}
.descriptionStyle {
  margin-bottom: 20px;
}
</style>
